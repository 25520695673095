import React, {FC} from 'react';

type LogoPropsType = {
    color: string
}

export const Logo: FC<LogoPropsType> = ({color}) => {
    return (
        <svg width="204" height="44" viewBox="0 0 204 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M22.201 42.9994L13.441 27.0993H10.981V42.9994H0.720947V0.879297H17.941C21.261 0.879297 24.081 1.4593 26.401 2.6193C28.761 3.7793 30.521 5.3793 31.681 7.41931C32.841 9.41931 33.421 11.6593 33.421 14.1393C33.421 16.9393 32.621 19.4393 31.021 21.6393C29.461 23.8393 27.141 25.3993 24.061 26.3193L33.781 42.9994H22.201ZM10.981 19.8393H17.341C19.221 19.8393 20.621 19.3793 21.541 18.4593C22.501 17.5393 22.981 16.2393 22.981 14.5593C22.981 12.9593 22.501 11.6993 21.541 10.7793C20.621 9.85931 19.221 9.39931 17.341 9.39931H10.981V19.8393Z"
                  fill={color}/>
            <path d="M50.1217 0.879297V42.9994H39.8616V0.879297H50.1217Z" fill={color}/>
            <path d="M68.477 34.5994H86.597V42.9994H56.837V35.1994L74.837 9.27931H56.837V0.879297H86.597V8.67931L68.477 34.5994Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M113.219 43.4194C109.259 43.4194 105.619 42.4994 102.299 40.6594C99.0192 38.8194 96.3992 36.2594 94.4392 32.9794C92.5192 29.6593 91.5592 25.9393 91.5592 21.8193C91.5592 17.6993 92.5192 13.9993 94.4392 10.7193C96.3992 7.43931 99.0192 4.87931 102.299 3.0393C105.619 1.1993 109.259 0.279297 113.219 0.279297C117.179 0.279297 120.799 1.1993 124.079 3.0393C127.399 4.87931 129.999 7.43931 131.879 10.7193C133.799 13.9993 134.759 17.6993 134.759 21.8193C134.759 25.9393 133.799 29.6593 131.879 32.9794C129.959 36.2594 127.359 38.8194 124.079 40.6594C120.799 42.4994 117.179 43.4194 113.219 43.4194ZM113.219 34.0594C116.579 34.0594 119.259 32.9394 121.259 30.6994C123.299 28.4593 124.319 25.4993 124.319 21.8193C124.319 18.0993 123.299 15.1393 121.259 12.9393C119.259 10.6993 116.579 9.57931 113.219 9.57931C109.819 9.57931 107.099 10.6793 105.059 12.8793C103.059 15.0793 102.059 18.0593 102.059 21.8193C102.059 25.5393 103.059 28.5193 105.059 30.7594C107.099 32.9594 109.819 34.0594 113.219 34.0594Z"
                  fill={color}/>
            <path d="M167.887 0.879297V9.09931H150.727V17.9793H163.567V25.9593H150.727V42.9994H140.467V0.879297H167.887Z"
                  fill={color}/>
            <path d="M203.58 0.879297V9.09931H192.42V42.9994H182.16V9.09931H171V0.879297H203.58Z" fill={color}/>
        </svg>

    );
};

