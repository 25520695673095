import noomb from "./assets/noombN.png";
import nuahuleN from "./assets/nuahuleN.png";
import opticsN from "./assets/opticsN.png";
import partyN from "./assets/partyN.png";
import prosN from "./assets/prosN.png";
import sodoxN from "./assets/sodoxN.png";
import cdN from "./assets/SDN.png";
import viskantN from "./assets/viskantN.png";

export type ClientsType = {
    img: string,
}

export const clients: ClientsType[] = [
    {img: prosN},
    {img: partyN},
    {img: cdN},
    {img: opticsN},
    {img: nuahuleN},
    {img: noomb},
    {img: sodoxN},
    {img: viskantN},
]
